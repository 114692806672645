import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "./SecondarySidebar.css";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";

const Academics = [
  {
    Header: "Academics",
    datas: [
      {
        title: "Study Materials",
        redirectUrl: "/faculty/secondsidebar/academics/studyMaterials",
      },
      {
        title: "Prev year Q&A",
        redirectUrl: "/faculty/secondsidebar/academics/prevYearQA",
      },
    ],
  },
];

const Admin = [
  {
    Header: "Admin",
    datas: [
      {
        title: "Proficiency Verification",
        redirectUrl: "/faculty/secondsidebar/admin/proficiencyValidation",
      },
      // {
      //   title: "Departments",
      //   redirectUrl: "/faculty/secondsidebar/admin/department",
      // },
      // {
      //   title: "Academic Year",
      //   redirectUrl: "/faculty/secondsidebar/admin/academicYear",
      // },
     
    ],
  },
];

const Collections = [
  {
    Header: "Collections",
    datas: [
      // {
      //   title: "Students",
      //   redirectUrl: "/faculty/secondsidebar/collections/students",
      // },
      {
        title: "Employees",
        redirectUrl: "/faculty/secondsidebar/collections/employees",
      },
    ],
  },
];

const Proficiency = [
  {
    Header: "Proficiency",
    datas: [
      {
        title: "Publications",
        redirectUrl: "/faculty/secondsidebar/proficiency/publications",
      },
      {
        title: "Patents",
        redirectUrl: "/faculty/secondsidebar/proficiency/patents",
      },
      {
        title: "Honours & Awards",
        redirectUrl: "/faculty/secondsidebar/proficiency/honoursawards",
      },
      {
        title: "Trainings",
        redirectUrl: "/faculty/secondsidebar/proficiency/trainings",
      },
      {
        title: "Research Guidance",
        redirectUrl: "/faculty/secondsidebar/proficiency/guidance",
      },
      {
        title: "Sponsored Research",
        redirectUrl: "/faculty/secondsidebar/proficiency/sponsoredresearch",
      },
      {
        title: "Online Courses",
        redirectUrl: "/faculty/secondsidebar/proficiency/onlinecourses",
      },
      {
        title: "Responsibilities",
        redirectUrl: "/faculty/secondsidebar/proficiency/responsibilities",
      },
      {
        title: "Books & Chapters",
        redirectUrl: "/faculty/secondsidebar/proficiency/bookschapters",
      },
      {
        title: "Consultancy",
        redirectUrl: "/faculty/secondsidebar/proficiency/consultancy",
      },
      // {
      //   title: "Result Analysis",
      //   redirectUrl: "/faculty/secondsidebar/proficiency/performance",
      // },
      // {
      //   title: "Appraisals",
      //   redirectUrl: "/faculty/secondsidebar/proficiency/appraisals",
      // },
    ],
    lists: [
      "Publications",
      "Patents",
      "Honours & Awards",
      "Trainings",
      "Guidance",
      "Sponsored Research",
      "Online Courses",
      "Responsibilities",
      "Books & Chapters",
      "Consultancy",
      "Result Analysis",
      "Appraisals",
    ],
  },
];

const Account = [
  {
    Header: "Account",
  },
];

const Reports = [
  {
    Header: "Reports",
  },
];

export const SecondarySideBarMobile = forwardRef(({ backMain }, ref) => {
  console.log(backMain);
  const [values, setValues] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/faculty/secondsidebar/proficiency")) {
      setValues(Proficiency);
    } else if (
      location.pathname.startsWith("/faculty/secondsidebar/academics")
    ) {
      setValues(Academics);
    } else if (location.pathname.startsWith("/faculty/secondsidebar/account")) {
      setValues(Account);
    } else if (location.pathname.startsWith("/faculty/secondsidebar/report")) {
      setValues(Reports);
    } else if (
      location.pathname.startsWith("/faculty/secondsidebar/collections")
    ) {
      setValues(Collections);
    }else if(
      location.pathname.startsWith("/faculty/secondsidebar/admin")
    ){
      setValues(Admin);
    }
  }, [location]);
  const GotoMainSide = () => {
    backMain();
  };

  useImperativeHandle(ref, () => ({
    backSideBar() {
      GotoMainSide();
    },
  }));

  return (
    <div className="d-md-none d-block bgSecondSidebar">
      <div className="mt-3">
        <p className=" cursorPointer ps-3" onClick={GotoMainSide}>
          <img src={""} alt="" className="me-2" />
          <span className="backFont">Back</span>
        </p>
        {values.map((value) => (
          <>
            <div className="sideNav d-flex flex-column">
              <ul className="sidebar">
                {value.datas?.map((item) => (
                  <li
                    className={`tab ${
                      location.pathname.startsWith(item.redirectUrl) && "active"
                    }`}
                  >
                    <Link
                      to={item.redirectUrl}
                      className="text-decoration-none titleSSB"
                    >
                      <span className="titleSSB">
                      {item.title}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </>
        ))}
      </div>
    </div>
  );
});

const SecondarySideBarWeb = () => {
  const [values, setValues] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/faculty/secondsidebar/proficiency")) {
      setValues(Proficiency);
    } else if (
      location.pathname.startsWith("/faculty/secondsidebar/academics")
    ) {
      setValues(Academics);
    } else if (location.pathname.startsWith("/faculty/secondsidebar/account")) {
      setValues(Account);
    } else if (location.pathname.startsWith("/faculty/secondsidebar/report")) {
      setValues(Reports);
    } else if (
      location.pathname.startsWith("/faculty/secondsidebar/collections")
    ) {
      setValues(Collections);
    }else if(
      location.pathname.startsWith("/faculty/secondsidebar/admin")
    ){
      setValues(Admin);
    }
  }, [location]);

  return (
    <>
      <div className="d-flex flex-column flex-md-row">
        <div className="col-1 sideWidth borderend d-none d-md-block">
          <div className="mt-4">
            <div className="sideNav d-flex flex-column">
              <ul className="sidebar">
                {values.map((value) => {
                  return (
                    <div key={value.id} className="">
                      <h5 className="headingSSB ps-3 ">{value.Header}</h5>

                      <ul>
                        {value.datas?.map((item) => (
                            <Link
                            to={item.redirectUrl}
                            className="text-decoration-none titleSSB"
                          >
                          <li
                            className={`tab ${
                              location.pathname.startsWith(item.redirectUrl) &&
                              "active"
                            }`}
                          >    
                              {item.title}
                          </li>
                          </Link>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="my-4 paddingContent w-100">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default SecondarySideBarWeb;
