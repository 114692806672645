import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import LogoDesktop from "../../assets/LoginPageAssets/bnr.png";
import LogoTablet from "../../assets/LoginPageAssets/TabletBanner/bnr.png";
import LogoMobile from "../../assets/LoginPageAssets/MobileBanner/bnr.png";
import vin from "../../assets/LoginPageAssets/logo.png";
import Google from "../../assets/Google.png";
import Mob from "../../assets/Mob.png";
import "./Login.css";
import { Values } from "../../utils/Types/Types";
import { login } from "../../ApiServices";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { string } from "yup";

type Props = {};

const Login = () => {
  const { keycloak, initialized } = useKeycloak();
  const [role, setRole] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Ensure Keycloak is initialized and authenticated
    if (initialized && keycloak?.authenticated) {
      const token = keycloak.token;
      const role = keycloak?.resourceAccess?.Kings_Faculty?.roles[0];

      if (token && role) {
        login(token)
          .then((res) => {
            console.log(res.data.data[0]);
            const empId = res.data.data[0].empId;
            sessionStorage.setItem("accessToken", token);
            sessionStorage.setItem("role", role);
            sessionStorage.setItem("empId", empId);

            // Redirect based on role
            if (role === "Faculty") {
              navigate("/faculty/secondsidebar/proficiency/publications");
            } else {
              navigate("/faculty/secondsidebar/collections/employees");
            }
          })
          .catch((err) => {
            console.error("Login API error:", err);
            keycloak.logout();
          });
      } else {
        console.error("Keycloak token or role is missing");
        keycloak.logout();
      }
    } else if (!initialized) {
      console.log("Keycloak is initializing...");
    }

    // Optionally add cleanup or retry logic if needed
  }, [keycloak, initialized, navigate]);

  return <div></div>;
};

export default Login;
