import React, { useRef, useState, ChangeEvent, FormEvent,useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import back from "../../../../../assets/back.png";
import image from "../../../../../assets/image.png";
import { PubliCationCreate, AcademicYear} from "../../../../../ApiServices";
import { ToastContainer , toast  } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../AddNew/AddNew.css"
import yearList from "../../../../../YearList.json" 


function PublicationCreate() {
  const navigate = useNavigate();
  const [noImage, setNoImage] = useState(true);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [uploadFiles, setUploadFiles] = useState<string | null>(null);
  const [imageBase, setBase64String] = useState<string>('');
  const [fileVal, setFileVal] = useState<string>('');
  const [toggleSubMod , setToggleSubMod] = useState(false)
  const [publiCationForm, setpubliCationForm] = useState({
    academicYear: "",
    publicationPhoto:"",
    doi: "",
    listedIn: "",
    title: "",
    journalName: "",
    hindex: "",
    i10Index: "",
    authorPosition: "",
    publicationUrl: "",
    uploadFile: "",
    impactFactor:"",
  });
  console.log(fileVal,8534574395);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);


  const handleSave=(e: FormEvent<HTMLButtonElement> )=>{
    setToggleSubMod(false)
    CreatePublication(e,"Yet to submit")
  }

  const handleSubmit=(e: FormEvent<HTMLButtonElement> )=>{
   if(validation() === true){
    if (!toggleSubMod) {
      setToggleSubMod(true);
  } else {
    setToggleSubMod(false);
    CreatePublication(e, "Pending");
  } 
   }else{}
  }


  const validation =()=>{
    setIsSubmitted(true);
    if (
      !publiCationForm.academicYear ||
      !publiCationForm.doi ||
      !publiCationForm.listedIn ||
      !publiCationForm.title ||
      !publiCationForm.journalName ||
      !publiCationForm.impactFactor||
      // !publiCationForm.hindex ||
      // !publiCationForm.i10Index ||
      !publiCationForm.impactFactor||
      !publiCationForm.authorPosition      
    ) {
      console.log("All fields are required");
      return;
    }
    // console.log(uploadFiles, "hello deqwwef")
    return true
  }

 
  const CreatePublication = (e: FormEvent<HTMLButtonElement> , status:any) =>{
    e.preventDefault();  
    setIsSubmitted(true);
    // toast.success("Publication Added")
    if (
      !publiCationForm.academicYear ||
      !publiCationForm.doi ||
      !publiCationForm.listedIn ||
      !publiCationForm.title ||
      !publiCationForm.journalName ||
      !publiCationForm.impactFactor||
      // !publiCationForm.hindex ||
      // !publiCationForm.i10Index ||
      !publiCationForm.impactFactor||
      !publiCationForm.authorPosition      
    ) {
      console.log("All fields are required");
      return;
    }
    console.log(uploadFiles, "hello deqwwef")
   
    // If all required fields are filled, proceed with creating the publication
    const payload = {
      empId:sessionStorage.getItem('empId'),
      academicYear: publiCationForm.academicYear,
      createdBy: "",
      doi: publiCationForm.doi,
      listedIn: publiCationForm.listedIn,
      publicationPhoto:imageBase,
      title: publiCationForm.title,
      journalName: publiCationForm.journalName,
      hindex: publiCationForm.hindex,
      i10Index: publiCationForm.i10Index,
      impactFactor:publiCationForm.impactFactor,
      authorPosition: publiCationForm.authorPosition,
      publicationUrl: publiCationForm.publicationUrl,
      uploadFile: uploadFiles === "" ? "" : uploadFiles,
      status:status
    };

    console.log(payload, "PublicationCreate");
    PubliCationCreate(payload)
      .then((res) => {
        setIsButtonClicked(true)
        navigate("/faculty/secondsidebar/proficiency/publications");
        console.log("bhgbycdfuiqgfou");
        setTimeout(()=>{
          toast.success(res.data.status);
        },200)
      })
      .catch((err) => {
        console.log(err);
        
      });
    console.log(payload);
  };
  // const academicYear = () =>{
  //   academicYearCall()
  //   .then((res:any)=>{
  //     console.log(res);
  //   })
  // }
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/,"");
    let updatedValue = value;
    if (value.length > 0) {
        var words = value.split(' ');
        for (var i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
        updatedValue = words.join(' ');
    }
    const except = name ===  "publicationUrl"   ? trimmedValue : name === "doi" ? trimmedValue : updatedValue;
    setpubliCationForm({
      ...publiCationForm,
      [e.target.name]: e.target.value,
      [name]: except 
    });
  };

  const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSize = 2 * 1024 * 1024;

    if (file && allowedTypes.includes(file.type)) {
        if (file.size <= maxSize) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const base64Image = reader.result as string; 
                setImageUrl(base64Image);
                setNoImage(false);
                setBase64String(base64Image);
            };
        } else {
            toast.error('File Size exceeds the maximum limit of 2 MB');
            event.target.value = '';
        }
    } else {
        toast.error('File format not supported. Please Try uploading image files');
        event.target.value = '';
    }
};

  const removeImage = () => {
    setImageUrl('');
    setNoImage(true);
    setBase64String("none");
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; 
    }
  };

  const handleBackButtonClick = () => {
    setShowModal(true);
  };
    // fileUpload
    const getBase64File = (file: File) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          onLoad(reader.result as string);
        };
    };
    const base64ConverterFile = (e: any) => {
      const file = e.target.files?.[0];
    if (!file) return;
      if (file.size > 2 * 1024 * 1024) {
        toast.error('File size exceeds 2 MB limit. Please upload a smaller file.');
        return;
      }
      if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
        toast.error('File format not supported. Please try uploading PDF or JPG files.');
        return;
      }else{
        getBase64File(file);
        handleChange(e);
      }
      setFileVal(file.name);
    }   
    const onLoad = (fileString: any) => {
      setUploadFiles(fileString);
    }
    const onlyNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const key = e.key;
      if (!/^[0-9]$/.test(key)) {
        e.preventDefault();
      }
    };

    const [academicYears,setAcademicYear] = useState([]);
    interface AcademicYear {
      id: number;
      academicYear: string;
      term: number;
      startDate: string;
      endDate: string;
    }
    const acad = ()=>{
      AcademicYear()
      .then((res =>{
        console.log(res.data.data);
        const uniqueAcademicYears = res.data.data.filter((item:any, index:any, self:any) =>
          index === self.findIndex((t:any) => (
            t.academicYear === item.academicYear
          ))
        );
        setAcademicYear(uniqueAcademicYears)
      })) 
    }
    const academicYearFromDb: AcademicYear[] = academicYears as AcademicYear[];
    useEffect(()=>{
      acad()
      },[])
      console.log(publiCationForm.uploadFile,"sdfhdefhwedf")
  return (
    <>

      <div className="modal fade" id="myModal" tabIndex={-1}>
        <div className="modal-dialog modalposition modal-sm">
          <div className="modal-content">
            <div className="text-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-header d-flex py-2 justify-content-center">
        <h5 className="modal-title submitHead">Unsaved Changes</h5>
      </div>
      <div className="d-flex justify-content-center">
           <p className='fontModel text-center px-5 mb-0'>Are you sure you want to discard the changes? Your changes will be lost.</p>
           </div>
           <div className="modal-footer row justify-content-center pb-5">
           <button
                type="button"
                className="btn cancelBtn "
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btnBack ms-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  setShowModal(false);
                  navigate("/faculty/secondsidebar/proficiency/publications"); 
                }}
              >
                Yes, discard
              </button>
              </div>
            </div>
        </div>
      </div>
      <ToastContainer/>
      <div className="mx-1">
      <div className="row">
  <div className="col-md-12 col-lg-4 col-12">
    <div className="mainheader">Add New Publication</div>
    <div className="greyfont mt-2">
      New Research publication published in the name of KEC (SCI/SCOPUS/UGC listed)
    </div>
  </div>
  <div className="col-md-12 col-lg-8 col-12 mobilescreen mt-sm-0 mt-md-2 mt-0 ">
    <div className="d-flex flex-column flex-md-row justify-content-md-end align-items-md-center">
      <span className="bacdept cursor-pointer labelinput d-flex align-items-center mb-2 mb-md-0">
        <span
          onClick={handleBackButtonClick}
          data-bs-toggle="modal"
          data-bs-target="#myModal"
          className="d-inline-block mb-2"
        >
          <img src={back} className="img-fluid me-2 link" alt="Back" />
          Back to Publications
        </span>
        <button
          className="ms-3 saveBtn"
          style={{ color: "white" }}
          onClick={handleSave}
          disabled={isButtonClicked}
        >
          Save
        </button>
        <button
          className="ms-3 addnewbtn"
          style={{ color: "white" }}
          onClick={handleSubmit}
          disabled={isButtonClicked}
        >
          Save & Submit
        </button>

        {toggleSubMod && (
          <>
            <div className="modal fade show" tabIndex={-1} style={{ display: "block" }}>
              <div className="modal-dialog modal-sm modal-dialog-centered">
                <div className="modal-content mt-2 mb-2">
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => {
                        setToggleSubMod(false);
                      }}
                    ></button>
                  </div>
                  <div className="modal-header d-flex justify-content-center">
                    <h5 className="modal-title submitHead">Submit for validation</h5>
                  </div>
                  <div className="">
                    <p className="fontModel items-center px-3 px-md-5 text-center">
                      This entry will be sent to admin for validation. Click 'Save' if you don't want to submit now.
                    </p>
                  </div>
                  <div className="modal-footer d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn bg-white text-black cancelBtn"
                      onClick={handleSave}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btnBack"
                      onClick={handleSubmit}
                    >
                      Save & Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {toggleSubMod && <div className="modal-backdrop fade show"></div>}
          </>
        )}
      </span>
    </div>
  </div>
</div>
<div className="mt-3 d-flex align-items-end flex-wrap">
  {noImage ? (
    <img src={image} alt="" className="me-3 img-fluid" />
  ) : (
    <img src={imageUrl} alt="Preview" className="me-3 img-fluid imgWidth" />
  )}
  <div>
    <div className="mb-2 labelinput">Front page of the publication</div>
    <div>
      <button
        onClick={() => document.getElementById("fileInput")?.click()}
        className="btn uploadBtn me-2 labelinput"
        style={{ border: "1px solid #A0AECB" }}
      >
        <span className="labelinput">Upload</span>
      </button>
      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        onChange={e => onFileSelected(e)}
      />
      <button
        className="btn uploadBtn labelinput"
        onClick={removeImage}
        style={{ border: "1px solid #A0AECB" }}
      >
        <span className="labelinput">Remove</span>
      </button>
    </div>
  </div>
</div>

        <form className="mt-2 labelinput">
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-3 col-xl-2">
              <label className="labelinput mt-2">Academic Year</label>
              <select
              className="form-select labelFont inputWidth"
              value={publiCationForm.academicYear}
              onChange={handleChange}
              name="academicYear"
              style={{
                fontSize: publiCationForm.academicYear ? '14px' : '12px',
                height: "38px",
                fontFamily: 'Satoshi medium',
                color: publiCationForm.academicYear ? 'black' : 'grey', 
              }}
            >
              <option value="" disabled style={{color:"black"}}>
                --Select--
              </option>
              {yearList.map((item) => (
    <option key={item} value={item} style={{ color: "black", fontSize: "14px" }}>
      {item}
    </option>
  ))}
            </select>
              {isSubmitted && !publiCationForm.academicYear && (
                <div className="is-invalid">*Required</div>
              )}
            </div>
            <div className="col-sm-4 col-md-4 col-lg-3 col-xl-2">
              <label className="labelinput mt-2">Listed In</label>
              {/* <input
                type="text"
                className="form-control mt-2 labelFont"
                placeholder="Listed In"
                value={publiCationForm.listedIn}
                onChange={handleChange}
                name="listedIn"
                required
                maxLength={50}
              />  */}
                  <select
              className="form-select labelFont inputWidth"
              // placeholder="Author Position"
              value={publiCationForm.listedIn}
              onChange={handleChange}
              name="listedIn"
              style={{
                fontSize: publiCationForm.listedIn ? '14px' : '12px',
                height: "38px",
                fontFamily: 'Satoshi medium',
                color: publiCationForm.listedIn ? 'black' : 'grey', 
              }}
              >
               <option value="" disabled className="select">--Select--</option>
                <option value="SCI" style={{color:"black" ,fontSize:"14px"}}>SCI</option>
                <option value="SCIE" style={{color:"black" ,fontSize:"14px"}}>SCIE</option>
                <option value="ESCI" style={{color:"black" ,fontSize:"14px"}}>ESCI</option>
                <option value="SCOPUS" style={{color:"black" ,fontSize:"14px"}}>SCOPUS</option>
                <option value="UGC" style={{color:"black" ,fontSize:"14px"}}>UGC</option>
              </select>
              {isSubmitted && !publiCationForm.listedIn && (
                <div className="is-invalid">*Required</div>
              )}
            </div>
          </div>
        </form>
        <form className="labelinput">
          <div className="row">
            <div className="col-sm-4 col-md-6 col-lg-6 col-xl-4">
              <label className="labelinput mt-2">Title of the Publication</label>
              <input
                type="text"
                className="form-control  labelFont inputWidth"
                placeholder="Enter Title of the Publication"
                value={publiCationForm.title}
                onChange={handleChange}
                name="title"
                required
                maxLength={200}
              />
              {isSubmitted && !publiCationForm.title && (
                <div className="is-invalid">*Required</div>
              )}
            </div>
            <div className="col-sm-4 col-md-6 col-lg-6 col-xl-4">
              <label className="labelinput mt-2">Name of the Journal</label>
              <input
                type="text"
                className="form-control labelFont inputWidth"
                placeholder="Enter Name of the Journal"
                value={publiCationForm.journalName}
                onChange={handleChange}
                name="journalName"
                required
                maxLength={100}
              />
              {isSubmitted && !publiCationForm.journalName && (
                <div className="is-invalid">*Required</div>
              )}
            </div>
          </div>
        </form>
        <form className="">
          <div className="row" style={{ fontSize: '14px', fontFamily: 'Satoshi medium' }}>
          <div className="col-sm-4 col-md-4 col-lg-3 col-xl-2">
              <label className="labelinput mt-2">DOI</label>
              <input
                type="tel"
                className="form-control labelinput inputWidth"
                placeholder="Enter DOI"
                value={publiCationForm.doi}
                onChange={handleChange}
                name="doi"
                required
                maxLength={50}
              />
              {isSubmitted && !publiCationForm.doi && (
                <div className="is-invalid">*Required</div>
              )}
            </div>
            {/* <div className="col-sm-4 col-md-4 col-lg-3 col-xl-2">
              <label className="labelinput mt-2">h-index</label>
              <input
                type="text"
                className="form-control labelFont inputWidth"
                placeholder="h-index"
                value={publiCationForm.hindex}
                onChange={handleChange}
                name="hindex"
                onKeyPress={onlyNumber}
                maxLength={5}
              />
           
            </div> */}
            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
              <label className="labelinput mt-2">Impact Factor</label>
              <input
                type="text"
                className="form-control labelFont inputWidth"
                placeholder="Impact Factor"
                value={publiCationForm.impactFactor}
                onChange={handleChange}
                name="impactFactor"
                // onKeyPress={onlyNumber}
                // maxLength={5}
              />

               {isSubmitted && !publiCationForm.impactFactor && (
                <div className="is-invalid">*Required</div>
              )}
            </div>
            <div className="col-sm-4 col-md-4 col-lg-3 col-xl-2">
              <label className="labelinput mt-2">Author Position</label>
              {/* <input
                type="number"
                className="form-control labelFont inputWidth"
                placeholder="Author Position"
                value={publiCationForm.authorPosition}
                onChange={handleChange}
                name="authorPosition"
              /> */}
              <select
              className="form-select labelFont inputWidth"
              // placeholder="Author Position"
              value={publiCationForm.authorPosition}
              onChange={handleChange}
              name="authorPosition"
              style={{
                fontSize: publiCationForm.authorPosition ? '14px' : '12px',
                height: "38px",
                fontFamily: 'Satoshi medium',
                color: publiCationForm.authorPosition ? 'black' : 'grey', 
              }}
              >
               <option value="" disabled className="select">--Select--</option>
                <option value="Single Author" style={{color:"black" ,fontSize:"14px"}}>Single Author</option>
                <option value="First Author" style={{color:"black" ,fontSize:"14px"}}>First Author</option>
                <option value="Second Author" style={{color:"black" ,fontSize:"14px"}}>Second Author</option>
                <option value="Third Author" style={{color:"black" ,fontSize:"14px"}}>Third Author</option>
                <option value="Co-author" style={{color:"black" ,fontSize:"14px"}}>Co-author</option>
                <option value="Senior Author" style={{color:"black" ,fontSize:"14px"}}> Senior Author</option>
                <option value="Corresponding Author" style={{color:"black" ,fontSize:"14px"}}>Corresponding Author</option>
                <option value="Equal Contribution" style={{color:"black" ,fontSize:"14px"}}>Equal Contribution</option>
                <option value="Collaborative Authorship" style={{color:"black" ,fontSize:"14px"}}>Collaborative Authorship</option>
              </select>
               {isSubmitted && !publiCationForm.authorPosition && (
                <div className="is-invalid">*Required</div>
              )}
            </div>
          </div>
        </form>
        <form className="labelinput">
          <div className="row mt-2">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 margintopalign">
              <label className="labelinput mt-2 ">
                Publication URL <span className="ms-3" style={{ color: 'grey' }}>Optional</span>
              </label>
              <input
                type="text"
                placeholder="Enter Publication URL"
                className="form-control labelFont inputWidth"
                value={publiCationForm.publicationUrl}
                onChange={handleChange}
                name="publicationUrl"
              />
            </div>
            {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
            <label className="labelinput mt-2 d-flex justify-content-between">
                <span className="" style={{color:'#1B1922'}}>Proof Document<span className="ms-1" style={{ color: '#7784A1' }}>&nbsp;Optional</span></span> <span className="" style={{ color: '#7784A1' }}>Only pdf/jpg file under 2MB</span>
              </label>
              <div className="input-group">
  <label className="form-control boxText boxtextellipse browsetext">
  {fileVal}
    <input
      type="file"
      id="fileInput2"
      className="invisible "
      onChange={(e) => base64ConverterFile(e)} 
    />
  </label>
  <span className="input-group-text lname cursorPointer"  onClick={() => document.getElementById("fileInput2")?.click()} >Select File</span>
</div>
</div>      */}
 <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
              
              <div className="d-flex justify-content-between mt-2 flex-wrap">
      <label className="labelinput">
        Marksheet
        <span className="optional ms-2">Optional</span>
      </label>
      <span className="optional text-nowrap">
        Only pdf/jpg file under 2MB
      </span>
    </div>
                <div className="input-group">
                  {/* <label className="form-control boxText boxtextellipse browsetext"> */}
                  <label className="form-control boxText boxtextellipse browsetext">
  {fileVal}
    <input
      type="file"
      id="fileInput2"
      className="invisible "
      onChange={(e) => base64ConverterFile(e)}
    />
  </label>
  <span className="input-group-text lname cursorPointer"  onClick={() => document.getElementById("fileInput2")?.click()} >Select File</span>
                </div>

                {/* <input
                      type="file"
                      name="file"
                      value={employeeIdForm.academicDetails.academics[0].file}
                      onChange={handleFileInputChange}
                      /> */}
                {/* {errorMessage && (
        <div className="mt-2 text-danger">
          <p>{errorMessage}</p>
        </div>
      )}
      {selectedFile && (
        <div className="mt-2">
          <p>Selected file: {selectedFile.name}</p>
        </div>
      )}
      {base64String && (
        <div className="mt-2">
          <p>Base64 string: {base64String}</p>
        </div>
      )} */}
              </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default PublicationCreate;


