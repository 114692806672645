import React, { useState, useEffect } from 'react'
import './styles.css'
import { Link, Outlet, useNavigate } from "react-router-dom"
import DynamicTables from '../../../../components/DynamicTable/DynamicTables'
import DynamicTable from '../../../../components/DynamicTable/DynamicTable'
import { employeeGetTable, employeedeleteSingleData, employeeupdateSingleData, imageCall } from '../../../../ApiServices'
import filter from '../../../../assets/filter.png'
import { employeeSingleData } from '../../../../ApiServices'
// import { getAllEmployeesBasedOnStatus } from '../../../../ApiServices'
import { MdCancel } from "react-icons/md";
import cross from "../../../../assets/crossimg.png";
import searchIcon from "../../../../assets/Icon search.svg"
import close from "../../../../assets/close.svg"
import CourseTable from '../../../../components/CourseDetailTable/CourseTable'
import "../../../FacultyModule/Proficiency/OnlineCourse/OnlineCourse.css";
import { ToastContainer, toast } from "react-toastify";
import ViewSingleEmployee from '../../../ViewSingleEmployee/ViewSingleEmployee'
import EmployeeBio from '../../../AdminModule/Employees/EmployeeBio/EmployeeBio'
import removeFilter from "../../../../assets/FilterRemove.svg"
import { useLocation } from 'react-router-dom'

type props = {
  data: any
}

function Employee() {
  const navigate = useNavigate();
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('Active');
  const columnRedirectsOnlineCourse = {
    courseTitle: "/proficiency/responsibilities/newResponsiblities/new-add",
  };
  const [isSearching, setIsSearching] = useState(true);
  const [currentPage, setCurrentPage] = useState(1)
  const [totalpages, settotalpages] = useState(0);
  const [TotalElements, setTotalElements] = useState(0);
  const [viewSingle, setViewSingle] = useState(false)
  const [sort, setSort] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableDatas, settableDatas] = useState([]);
  const [sortusing, setSortUsing] = useState("coId");
  const [selectedItem, setselectedItem] = useState<any>([])
  const [age, setAge] = useState<number | null>(null)
  const [employeeIds, setEmployeeIds] = useState();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState()
  const [employeeTypeFilter, setEmployeeTypeFilter] = useState<string>("");
  const [activeFilterCount, setActiveFilterCount] = useState(0);


  useEffect(() => {
   if(location?.state?.toastMessage){
    toast.success(location.state.toastMessage)
   }
  }, [location?.state])

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalpages));
    console.log("next button clicked and the value now is " + currentPage);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    console.log(currentPage)
  };

  const handleSort = () => {
    setSortUsing("academicYear");
    setSort(!sort);
  };

  const sortOrder = sort ? "asc" : "desc";

  const calculateAge = (dob: string) => {
    const birthday = new Date(dob);
    const ageDiffMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDiffMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };


  const getDatas = () => {

    const data = {
      status: activeTab === 'Active' ? 'Active' : 'Inactive',
      keyword: searchTerm,
      type: employeeTypeFilter,
      designation: "",
      department: "",
      experience: "",
      employeeType: '',
      page: currentPage - 1,
    }


    employeeGetTable(data)
      .then((res) => {
        const transformedData = res.data.datas.content.map((item: any) => ({
          ...item,
          mobileNumber: `+91 ${item.mobileNumber}`
        }));
        settableDatas(transformedData);
        console.log(transformedData, "");
        setAge(res.data.dob);
        setTotalElements(res.data.datas.totalElements);
        settotalpages(res.data.datas.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [filterOptions, setFilterOptions] = useState({
    status: activeTab === 'Active' ? 'Active' : 'Inactive',
    keyword: '',
    type: employeeTypeFilter,
    designation: '',
    department: '',
    experience: '',
    employeeType: '',
  });
  // const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (e) => {
  //   const { name, value } = e.target;
  //   setFilterOptions({
  //     ...filterOptions,
  //     [name]: value
  //   });
  // };

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (e) => {
    const { name, value } = e.target;
    if (name === 'type') {
      setEmployeeTypeFilter(value);
    }
    const updatedFilter= {
      ...filterOptions,
      [name]: value
    };
    console.log(updatedFilter , "Gopi")
    // setEmployeeTypeFilter((prev:any)=> [...prev , value])
    setFilterOptions(updatedFilter)

    const count = Object.values(updatedFilter).filter((filter) => filter && filter !== '--Select--' && filter !== "Active").length;
    setActiveFilterCount(count);
  };

  const Header = [
    { label: "Employee No.", key: "employeeId", style: { textDecoration: 'underline' } },
    { label: "Name", key: "employeeName" },
    { label: "Type", key: "employeeType" },
    { label: "Department", key: "department" },
    { label: "Designation", key: "designation" },
    { label: "Mobile No", key: "mobileNumber" },
    { label: "Email Address", key: "email" },
  ];


  const ProfileViewHeaders = [

    { title: "Name", key: "employeeName" },
    { title: "Employee Type", key: "employeeType" },
    { title: "Employee Id", key: "employeeId" },
    { title: "Designation", key: "designation" },
    { title: "Contact No", key: "mobileNumber" },
    { title: "Joined on", key: "joinedOn" },
    { title: "Department", key: "department" },
    { title: "Designation", key: "designation" },
    { title: "Reporting To", key: "reportingTo" },
    { title: "Past experience (in yrs)", key: "experience" },
    { title: "imgage", key: "imageName" },

    { title: "Date of Birth", key: "dob" },
    { title: "Age", key: "" },
    { title: "Gender", key: "gender" },
    { title: "Blood Group", key: "bloodGroup" },
    { title: "Community", key: "community" },
    { title: "Religion", key: "religion" },
    { title: "Nationality", key: "nationality" },
    { title: "Aadhar", key: "aadhaarNumber" },

    { title: "Email Address", key: "email" },
    { title: "Phone No", key: "mobileNumber" },
    { title: "Present Address", key: "presentAddress" },
    { title: "Permanent Address", key: "permanentAddress" },
  ]

  const backtoview = "Employees"

  useEffect(() => {
    getDatas();
  }, [filterOptions, currentPage, searchTerm, sort]);


  const applyFilter = () => {

    employeeGetTable(filterOptions)
      .then((res) => {
        const transformedData = res.data.datas.content.map((item: any) => ({
          ...item,
          mobileNumber: `+91 ${item.mobileNumber}`
        }));
        settableDatas(transformedData);
        console.log(transformedData, "");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const clearFiltertype = () => {
    setEmployeeTypeFilter('');
    clearFilter();
  };

  const clearFilter = () => {
    setFilterOptions(prevFilterOptions => ({
      ...prevFilterOptions,
      status: 'Active',
      keyword: employeeTypeFilter,
      type: '',
      designation: '',
      department: '',
      experience: '',
    }));
  };
  const reloadPage = () => {
  }

  const deleteApi = (data: any) => {
    employeedeleteSingleData(data)
      .then((res) => {
        console.log(res.data.status);
        console.log(res.data)
        alert(res.data.status);
        getDatas()
      })
      .catch((err) => {
        console.log(err)
      });
  }


  async function navigateView(item: any) {
    try {
      const calculatedAge = calculateAge(item.dob);

      const response = await employeeupdateSingleData(item.employeeId);

      console.log('API Response:', response);

      if (response && response.data && response.data.data && response.data.data.length > 0) {
        const employeeData = response.data.data[0];
        console.log('Fetched Employee Data:', employeeData);

        setselectedItem(employeeData);
        setEmployeeIds(employeeData.employeeId);
        setImageUrl(employeeData.image);
        setAge(calculatedAge);

        navigate(`/faculty/secondsidebar/collections/employees/employeebio/${item.employeeId}`);
      } else {
        console.error('Unexpected API response structure:', response);
      }
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  }

  const handleTabChange = (tab: string) => {
    const status = tab === 'Active' ? 'Active' : 'Inactive';
    setActiveTab(tab);
    setCurrentPage(1);
    setFilterOptions(prevFilterOptions => ({
      ...prevFilterOptions,
      status: status
    }));
  };
  function editCourse(item: any) {
    navigate("editemploye/" + item.employeeId)
    const toggleSearch = () => {
      setIsSearching(!isSearching);
      setSearchTerm("");
    };
  }
  const toggleSearch = () => {
    setIsSearching(!isSearching);
    setSearchTerm("");
    setIsSearchOpen(!isSearchOpen);
    if (!isSearchOpen) {
      setSearchTerm('');
    }
  };
  const handleCancel = () => {
    setSearchTerm('');
    setIsSearchOpen(false);
  };
  const employeeHeader = "Profile Info"

  return (
    <>
      {!viewSingle ?
        <div>
          <ToastContainer />

          {/* <div className='d-flex'>
                  <div className='col-md-10 col-lg-11 col-7'>
                    <div className="mainheader">Employees</div>
                  
                    <div className="greyfont mt-1">List of all the employees</div>
                  </div>
                  <div className="float-end">
                    <Link to="/faculty/secondsidebar/collections/employees/employeecreate" className="link">
                      <span className='bacdept cursorPointer  '>
                        <button className="ms-3 addnewbtn" style={{ color: 'white' }} >Create New</button>
                      </span>
                    </Link>
                  </div>



                </div> */}

          <div className="container-fluid">
            <div className="row">
              <div className="col ps-0">
                <div className="mainheader">Employees</div>

                <div className="greyfont mt-1">List of all the employees</div>
              </div>
              <div className="col text-end pe-0">
                <Link to="/faculty/secondsidebar/collections/employees/employeecreate" className="link">
                  <span className='bacdept cursorPointer  '>
                    <button className="ms-3 addnewbtn" style={{ color: 'white' }} >Create New</button>
                  </span>
                </Link>
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-between'>
            <div className='col-md-6 col-lg-6 col-6'>
              <ul className="nav mt-4 tabfont cursorPointer">
                <li className="nav-item tablink">
                  <span
                    className=''
                    style={{ color: activeTab === 'Active' ? '#000000' : '#7784A1' }}
                    onClick={() => handleTabChange('Active')}
                  >
                    Active {activeTab === 'Active' ? (
                      <span> ({TotalElements})</span>
                    ) : null}
                    <span style={{
                      display: 'block',
                      borderBottom: activeTab === 'Active' ? '3px solid #4075D3' : 'none',
                      paddingTop: activeTab === 'Active' ? '7px' : '0',
                    }}>
                    </span>
                  </span>
                </li>
                <li className="ms-3 tablink">
                  <span
                    className='mt-2'
                    style={{ color: activeTab === 'Inactive' ? '#000000' : '#7784A1' }}
                    onClick={() => handleTabChange('Inactive')}
                  >
                    Inactive{activeTab === 'Inactive' ? (
                      <span> ({TotalElements})</span>
                    ) : null}
                    <span style={{
                      display: 'block',
                      borderBottom: activeTab === 'Inactive' ? '3px solid #4075D3' : 'none',
                      paddingTop: activeTab === 'Inactive' ? '7px' : '0',
                    }}>
                    </span>
                  </span>
                </li>
              </ul>
              {employeeTypeFilter && (
                <span className='emptype labelinput'>
                <span className='px-2'>{employeeTypeFilter}</span>
                <img src={removeFilter} className='clearFilterIcon me-2'onClick={clearFiltertype} ></img>
                {/* <MdCancel className='clearFilterIcon me-2' onClick={clearFiltertype} /> */}
              </span>
              )}
            </div>
            <div className='d-flex float-end mt-3'>
              <div className="search me-4" style={{ boxShadow: 'none' }}>
                <img
                  alt="search"
                  className="fasearch mb-2"
                  style={{ top: isSearchOpen ? '10px' : 'revert-layer',cursor:'pointer' }}
                  src={searchIcon}
                  onClick={toggleSearch}
                />
                {isSearchOpen && (
                  <div className="search-input-container">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                    />
                    <img src={close} alt="" onClick={handleCancel} style={{ position: 'absolute', top: '17px', right: '10px', transform: 'translateY(-50%)', width: '15px', height: '15px', cursor: 'pointer' }} />
                  </div>
                )}
              </div>

              <div className={`${isSearchOpen ? 'ms-auto' : 'ms-3'} float-end  mt-1`}>

                <img
                  src={filter}
                  
                  style={{cursor:'pointer'}}
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasExample"
                  aria-controls="offcanvasExample"
                />
                 <span className='activefilter'>{activeFilterCount}</span>
              </div>
            </div>

          </div>

          <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{ marginTop: '5.8rem', width: '300px' }}>
            <div className="offcanvas-header">
              <h5 className="offcanvas-title mainheader justify-content-between" id="offcanvasExampleLabel">Filters</h5>
              <span className='filter'>
                <button type="button" className="btn-close text-reset ms-5" data-bs-dismiss="offcanvas" aria-label="Close" onClick={reloadPage}></button>
              </span>
            </div>
            <div className="offcanvas-body px-4">
              <div className="d-flex flex-column ">
                <div className="mb-auto labelinput">


                  <div>
                    <label className="form-label lname font-13">Employee Type</label>
                    <div className="form-check d-flex">
                      <input
                        className="form-check-input me-2"
                        type="checkbox"
                        name="type"
                        onChange={handleChange}
                        checked={filterOptions.type === 'Office Staff'}
                        value="Office Staff"
                      />
                      <label className="form-check-label">Office Staff</label>
                    </div>
                    <div className="form-check d-flex">
                      <input
                        className="form-check-input me-2"
                        type="checkbox"
                        name="type"
                        onChange={handleChange}
                        checked={filterOptions.type === 'Faculty'}
                        value="Faculty"
                      />
                      <label className="form-check-label">Faculty</label>
                    </div>
                    <div className="form-check d-flex">
                      <input
                        className="form-check-input me-2"
                        type="checkbox"
                        name="type"
                        onChange={handleChange}
                        checked={filterOptions.type === 'Maintenance'}
                        value="Maintenance"
                      />
                      <label className="form-check-label">Maintenance</label>
                    </div>
                    <div className="form-check d-flex">
                      <input
                        className="form-check-input me-2"
                        type="checkbox"
                        name="type"
                        onChange={handleChange}
                        checked={filterOptions.type === 'House Keeping'}
                        value="House Keeping"
                      />
                      <label className="form-check-label">House Keeping</label>
                    </div>

                    <div>
                      <label className="form-label lname font-13 mt-5">Designation</label>

                      <select
                        className="form-select mt-2 labelinput"
                        value={filterOptions.designation}
                        onChange={handleChange}
                        name="designation"
                        style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                      >
                        <option selected>--Select--</option>
                        <option value="Professor">Professor</option>
                        <option value="Associate Professor">
                          Associate Professor
                        </option>
                        <option value="Assistant Professor">
                          Assistant Professor
                        </option>
                      </select>
                    </div>

                    <div>
                      <label className="form-label lname font-13 mt-5 ">Department</label>

                      <select
                        className="form-select mt-2 labelinput"
                        value={filterOptions.department}
                        onChange={handleChange}
                        name="department"
                        style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                      >
                        <option selected>--Select--</option>
                        <option value=" Artificial Intelligence & Data Science"> Artificial Intelligence & Data Science</option>
                        <option value="Artificial Intelligence & Machine Learning">Artificial Intelligence & Machine Learning</option>
                        <option value=" Biomedical Engineering">Biomedical Engineering</option>
                        <option value=" Computer Science and Engineering">Computer Science and Engineering</option>
                        <option value="lectronics and Communication Engineering">Electronics and Communication Engineering</option>
                        <option value="Information Technology">Information Technology </option>
                        <option value="Mechanical Engineering"> Mechanical Engineering</option>
                        <option value="Robotics and Automation">Robotics and Automation</option>
                        <option value="Physics"> Physics</option>
                        <option value="Chemistry"> Chemistry </option>
                        <option value="Mathematics"> Mathematics </option>
                        <option value="English"> English </option>
                      </select>
                    </div>

                    {/* <div>
                      <label className="form-label lname font-13 mt-5">Years Of Experience</label>
                      <input
                        type="text"
                        className="form-control"
                        value={filterOptions.experience}
                        onChange={handleChange}
                        name='experience'
                      />
                    </div> */}

                    <div>

                      <div className="text-center" style={{ marginTop: '11rem' }}>
                        <button type="submit" className="btn ApplyBtn btn-light btnwith" style={{background:'#fff',fontSize:'12px',color:'#1B1922',fontFamily:'Satoshi Medium',border:'1px solid #D0D0E0',borderRadius:7}} onClick={applyFilter}>
                          Apply
                        </button>
                      </div>
                      <div className="text-center pt-2">
                        <button className="btn clearFilter" style={{fontSize:'12px',color:'#1B1922',fontFamily:'Satoshi Medium'}} onClick={clearFilter}>
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>


                </div>

                <div>

                </div>
              </div>
            </div>

            {/* <button className='' onClick={navigate}>click</button> */}
          </div>
          <DynamicTables
            editCourse={editCourse}
            currentPage={currentPage}
            pages={totalpages}
            data={tableDatas}
            headers={Header}
            showSNo ={false}
            columnRedirects={columnRedirectsOnlineCourse}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
            TotalElements={TotalElements}
            handleChangePage={handleChangePage}
            handleSort={handleSort}
            deleteApi={deleteApi}
            navigateView={navigateView} // Ensure this is correctly wired up
          />

          {/* <DynamicTable /> */}
        </div>
        :
        // <CourseTable data={selectedItem} back={() => { setViewSingle(!viewSingle) }}
        //   headers={ProfileViewHeaders}
        //   employeeHeader={employeeHeader}
        //   backtoview={backtoview}
        //   employeeIds={employeeIds}

        // />
        <EmployeeBio data={selectedItem} back={() => { setViewSingle(!viewSingle) }} imageUrl={imageUrl} />

      }
      <Outlet />
    </>

  )
}

export default Employee;

