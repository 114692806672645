
import React, { useEffect, useState } from 'react';
import { GoArrowLeft } from "react-icons/go";
import "../CourseDetailTable/CourseTable.css"
import { Link, useParams, useNavigate, Navigate } from 'react-router-dom';
import crossicon from "../../../src/assets/Icon ionic-ios-close-circle.svg"
import { DeleteTabledata, OnlineCourseGetTableData, editHistory ,rejectCallProficiency ,approveCallProficiency ,host} from '../../ApiServices';
import { title } from 'process';
import back from "../../../src/assets/back.png"
import user from "../../../src/assets/user.png"
import { RxAvatar } from "react-icons/rx";
import EditEmployee from '../../pages/FacultyModule/Collections/Employees/EditEmployee/EditEmployee';
import { Modal, Button } from 'react-bootstrap'
import created from "../../assets/created.png"
import { Certificate } from 'crypto';
import submitted from "../../assets/submitted.png"
import verfied from "../../assets/verified.png"
import pending from "../../assets/Icon ionic-ios-alert.png"
import commentsIcon from "../../assets/comment.png"
import verified from "../../assets/verifiedViewPage.png"
import YetToSubmit from "../../assets/yet to submit.png"

// import rejected from "../../assets/Icon ionic-ios-close-circle.png"
import rejected from "../../assets/rejected.png"
import cancel from "../../assets/Icon ionic-ios-close-circle.png"
import tick  from "../../assets/Icon ionic-ios-checkmark-circle.svg"
import { ToastContainer , toast  } from "react-toastify";
type Props = {
  employeeHeader?: any
  formattedUpdatedDate?: string
  data: any[]
  editCourse?:any
  TableSubmit?:any
  showSNo?:boolean
  profileImg?:any
};
const dummydata = [
  {
    "id": 555,
    "filedName": "EmployeeName",
    "oldValue": "Ramya. B",
    "newValue": "ramba .r",
    "updatedBy": null,
    "employeeId": "KECEMP21080003",
    "updatedOn": "2024-05-15T09:13:41.317+00:00"
  }
]

const EditProfileHeaders = [
  {
    "title": "Field Name",
    "key": "filedName"
  }, {
    "title": "Old Value",
    "key": "oldValue"
  }, {
    "title": "New Value",
    "key": "newValue"
  }, {
    "title": "Updated On",
    "key": "updatedOn"
  }
]

const CourseTable = ({ data, back, headers, backtoview, backlink, heading, subheading, employeeHeader , createdOn, updatedOn, deleteApi, deleteCont, deleteApiView, FileDownload, imageDownload, period, pubIdView,editCourse ,TableSubmit , showProficiencyVlidation , profileImg = false,sectionForView}: any) => {
  console.log(data , period ,  "dataaa" )
  const [selectedItem, setselectedItem] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dob, setDob] = useState('');
  const [age, setAge] = useState(null);
  const [pubImage, setPubImage] = useState(true);
  const [booImage, setBooImage] = useState(true);
  const [editHisModal, SeteditHisModal] = useState(false);
  const [editHistoryData, setEditHistoryData] = useState([])
  const [loading, setLoading] = useState(false);
  console.log(data,"gugyjugf");
  const [imageUrl, setImageUrl] = useState<string>('');
  const [booImageUrl, setBooImageUrl] = useState<string>('');
  const [submitToggle , setSubmitToggle] = useState(false)
  const [reject , setReject] = useState(false)
  const [approve , setApprove] = useState(false)
  const [textArea , setTextArea] = useState("")
  const [btnDisable , setBtnDisable] = useState(false)


  let proficiencyData: any[] =[]
  proficiencyData.push(data[0].proficiency)
  console.log(data[0].proficiency , "proficiency dataaaaaaaaaaaa")
  const activityHistory = data[0].activityHistories
  const validationProficiency: any[]= []
  validationProficiency.push(data[0].validation)
  console.log(validationProficiency , " validations")
  console.log(proficiencyData ," proficiency data ")
  console.log(activityHistory , " activity history ") 
  console.log(data[0].validation , "checkkk")  
  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };
  
  const navigate = useNavigate()
  const getTitle = (heading: any) => {  
    if (heading?.length > 25) {
      return heading.substring(0, 25) + '...';
    }
    return heading;
  };

  const toggleSubmit =(item:any)=>{
    setSubmitToggle(true)
    setselectedItem(item)
    }

  const ToggleReject =()=>{
  setReject(true)
  }

  const ToggleApprove =()=>{
  setApprove(true)
  }
const [mandatory,setMandatory]=useState<boolean>(false);
  const rejectCall =()=>{
    if(textArea !=''){
      setMandatory(false);
    setBtnDisable(true)
    const payload ={
      "sectionId":activityHistory[0].sectionId,
      "status" : "Rejected", 
      "comments" : textArea,
      "updatedBy" : sessionStorage.getItem("role")
    }
    rejectCallProficiency(payload)
    .then((res:any)=>{
      toast.success("Request Rejected Successfully")
      // window.location.reload(); 
      // back()
      setTimeout(() => {
        back();
      }, 1000);
    }).catch((err)=>{
      console.log(err)
      setBtnDisable(false)
    })
    setReject(false)
    setTextArea("")
  }
  else{
    setMandatory(true);
  }
  }
   
  const approveCall =()=>{
    setBtnDisable(true)
    const payload ={
      "sectionId":activityHistory[0].sectionId,
      "status" : "Verified", 
      "comments" : "",
      "updatedBy" : sessionStorage.getItem("role")
    }
    approveCallProficiency(payload)
    .then((res:any)=>{
      toast.success("Request Verified Succesfully")
      // window.location.reload(); 
      // back() 
      setTimeout(() => {
        back();
      }, 1000); 
    }).catch((err)=>{
      console.log(err)
      setBtnDisable(false)
    })
    setApprove(false)
  }

  useEffect(() => {
    console.log(sectionForView,"asdsdad",pubIdView,);
    
    const fetchData = async () => {
      if (subheading === "View details of the research published" || sectionForView === "Publications") {
         const imageUrls = host+"proficiency/get/publication?pubId=" + pubIdView + "&keyword=photo";
        try {
          const response = await fetch(imageUrls);
          if (response.ok) {
            setPubImage(true);
            setImageUrl(imageUrls);            
          } else {
            setPubImage(false);
          }
        } catch (error) {
          console.error("Error making HTTP call:", error);
          setPubImage(false);
        }
      } else if (subheading === "View details of the book published" || sectionForView === "Books & Chapters" ) {
        const booImageUrls = host+"proficiency/get/booksAndChapter?bacId=" + pubIdView + "&keyword=photo";
        try {
          const response = await fetch(booImageUrls);
          if (response.ok) {
            setBooImageUrl(booImageUrls);
            setBooImage(true);
          } else {
            setBooImage(false);
          }
        } catch (error) {
          console.error("Error making HTTP call:", error);
          setBooImage(false);
        }
      }
      setLoading(true);
    };
  
    fetchData();
  
    // Cleanup function
    return () => {
      // If you have any cleanup to do, put it here
    };
  }, [subheading, pubIdView]);

  const editEmployee = () => {
    {
      data.map((item: any, index: any) => {
        navigate("editemploye/" + item.employeeId)
      })
    }
  }
  const handleEditHistoryClick = () => {
    SeteditHisModal(true);
    {
      data.map((item: any, index: any) => {
        editHistory(item.employeeId)
          .then((res: any) => {
            setEditHistoryData(res.data.data)

            console.log(res.data.data[0], "HIIIIIIIIIIIIIIILLLLLLLLLLLLOOOOOOOOOOWWWWWW")
          })
          .catch((err: any) => {
            console.log(err)
          })
      })
    }
  };
 
  console.log(data, "am the data ")
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
  
    const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
  
    const formattedDate = new Intl.DateTimeFormat('en-GB', dateOptions).format(date);
    const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date).replace(' ', '');
  
    return `${formattedDate}, ${formattedTime}`;
  };
  return (
    <>
  {showProficiencyVlidation && 
  <>
   <ToastContainer/>
  {validationProficiency.map((item:any,index:any)=>(
    <div className='ProficiencyValidationTab'>
    <div className='ProficiencyValidation'>
    <span className='row'>Proficiency Verification</span>
     <div className='d-flex justify-content-between'>
      <div>
        <span className='requestt row'>
          View proficiency Verification request '{item.empId}'
        </span>
      </div>
      <div>
        {backtoview && (
          <span
            className='bacdept cursorPointer labelinput me-3'
            onClick={() => back()}
          >
            <img className="img-fluid me-2" />
            <span className='me-2'>
              <GoArrowLeft />
            </span>
            <span>Back to {backtoview}</span>
          </span>
        )}
      </div>
    </div>
    
    </div>  
    <div className='d-flex align-items-center'>
    <div className='d-md-flex col empDetails'>
      <div className='mx-md-2'>
        {profileImg ?
        <img src={profileImg} style={{height:"45px",width:"45px", marginRight:"16px"}}/> :
        <img src={user} style={{height:"45px",width:"45px"}}></img>
      }
      </div>
     <div className=''>
     <span>{item.name}</span>
     <span className='row mx-1'> { item.empId } | {item.designation} | {item.department} </span>
     </div>
    </div>
    {item.status === "Pending" ? 
   <div className='text-end'>
   <button className='btnValidation' style={{backgroundColor:"#FFE1E1"}} disabled={btnDisable} onClick={ToggleReject}><span className='mx-1'><img src={cancel}></img></span>Reject Entry</button>
   <button className='btnValidation mt-2 mt-md-0' style={{backgroundColor:"#DAF7F1"}} disabled={btnDisable} onClick={ToggleApprove}><span className='mx-1'><img src={tick}></img></span>Mark as verified </button>
 </div>
    : ""
      }
    </div>
    </div>
  ))}
  </>
   }
      <div className=''>
      <div className="row">
        <div className="col-md-12 col-lg-5 col-12">
          {data.map((item: any, index: any) => {
            return (
              <>
                {employeeHeader === "Profile Info" && (
                  <>
                    <span className='mainheader'>{item.employeeId}</span>
                    <p className='greyforview'>View details about the employee '{item.employeeId}'</p>
                    <div className='d-flex'>
                      <img src={user} style={{ width: "96px" }}></img>
                      <div className='row mx-2'>
                        <span className='extraDatas'>{item.employeeName}</span>
                        <span className='employeeView'>{item.designation}</span>
                        <span className='employeeView'>{item.department}</span>
                        <span className='employeeView'>Employee since {new Date(item.joinedOn).getFullYear()}</span>
                      </div>
                    </div>
                  </>
                )}
                {employeeHeader !== "Profile Info" && (
                  proficiencyData.map((item:any,index:any)=>(
                    <>
                    <div className='d-flex align-items-center gap-3'>
                    <div className="mainheader">{getTitle(heading)}</div>
                    <div className=''>
                    <div className={item.status === "Yet to submit" ? "bg-YetToSubmitView d-flex" : item.status === "Verified" ? "bg-VerifiedView d-flex":item.status === "Pending" ? "bg-PendingView d-flex":item.status === "Rejected" ? "bg-RejectedView d-flex":""} >
                            {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIconView"/>}
                            {item.status === "Pending" && <img src={pending} className="statusIconView"/>}
                            {item.status === "Rejected" && <img src={cancel} className="statusIconView" />}
                            {item.status === "Verified" && <img src={verfied} className="statusIconView"  />} 
                            <div className='statusView'>{item.status}</div>
                          </div>
                          </div>
                    </div>
                    <div className="greyfont mt-2 mb-3">{subheading}</div>
                    {subheading === "View details of the research published" && pubImage && (
                      <img className='imageWidth' src={imageUrl} alt="" />
                    )}
                      {subheading === "View details of the book published" && booImage && (
                      <img className='imageWidth' src={booImageUrl} alt="" />
                    )}
                    {sectionForView === "Publications" && pubImage && (
                      <img className='imageWidth' src={imageUrl} alt="" />
                    )}
                     {sectionForView === "Books & Chapters" && booImage && (
                      <img className='imageWidth' src={booImageUrl} alt="" />
                    )}
                    {/* <span className='d-flex cursorPointer footer'>
                      <span className='greyforview me-3'>Created on {createdOn} </span>
                      {updatedOn !== "" &&
                        <span className='greyforview'>Last Updated on {updatedOn} </span>
                      }
                    </span> */}
                  </>
                ))
                )}
              </>
            );
          })}
        </div>
        <div className="col-md-12 col-lg-7 col-12">
          <div className="float-end">
           {showProficiencyVlidation ? "":
           <span className='bacdept cursorPointer labelinput me-3' onClick={() => back()}>
           <img className="img-fluid me-2 " />
           <span className='me-2'> <GoArrowLeft /></span>
           <span> Back  {backtoview} </span>
         </span>
           }
            
            {data.map((item:any,index:any)=>{
                console.log(item , "am the itemmmmmmmmmmmmmm")
                return(
                  <>
                  {sessionStorage.getItem('role') == "Faculty" && item.proficiency.status == "Rejected" && !item.validation || sessionStorage.getItem('role') == "Faculty" && item.proficiency.status == "Verified" && !item.validation  ?
                   <>
                <button className='saveBtn ms-3 fw-bold' onClick={()=>editCourse(item)}>Edit</button>
                   </>:""
                   }
 
                {item.proficiency.status == "Yet to submit" && sessionStorage.getItem('role') =="Faculty" ?
                   <>
                <button className='saveBtn ms-3 fw-bold' onClick={()=>editCourse(item)}>Edit</button>
                {/* <button className='addnewbtn ms-3' onClick={()=>TableSubmit(item.proficiency)}>Submit</button> */}
                <button className='addnewbtn ms-3' onClick={()=>toggleSubmit(item.proficiency)}>Submit</button> 
                   </>:""
                   }
                   </>
                )   
                })
            }
           
            {/* </Link>*/}
          </div>
        </div>
        {employeeHeader === "Profile Info" && (
          <div className='d-flex justify-content-between'>
            <span className='tabfontemp pt-3'>Profile Info</span>
            <div className="d-flex">
              <span className='profiletable pt-2 me-4' onClick={handleEditHistoryClick}>Profile Edit History</span>
              <span className=''>
                <button className='btn sendApproval' onClick={editEmployee}>Edit Profile Info</button></span>
            </div>
          </div>
        )}
      </div>

      <div className='table-responsive'>
        <table className="col-12 col-md-12 partiTable mt-3">
          <tbody>
            {headers?.map((header: any, index: any) => (
              <React.Fragment key={index}>
                <tr className='col-md-12 col-12'>
                  <td className='col-md-1 col-6 '>{header.title}</td>
                  {/* <td>{employeeHeader}</td> */}
                  {/* {proficiencyData?.map((item: any, dataIndex: any) => (
                    <>
                      <td className='col-6 dataDec' key={dataIndex}>
                        {header.key === 'uploadFile' || header.key === 'fileUpload' ? (
                          <span className='cursorPointer' style={{ textDecoration: 'underline' }} onClick={FileDownload}>
                            {item[header.key] || "-"}
                          </span>
                        ) : (
                          header.key === 'period' ? (
                            <span>{period || "-"}</span>
                          ) : (
                            header.key === 'amountSanction' ?
                            "₹ " + new Intl.NumberFormat('en-IN').format(item[header.key]) : header.key === 'completionPercentage' ? item[header.key] + "%" : header.key === "academicYear" ? item[header.key].replace("-" , " - "): item[header.key]
                          )
                        )}
                      </td>

                      
                     
                    </>
                  ))} */}
                  {proficiencyData?.map((item: any, dataIndex: any) => (
  <td className='col-6 dataDec' key={dataIndex}>
    {header.key === 'uploadFile' || header.key === 'fileUpload' ? (
      item[header.key] ? (
        <span className='cursorPointer' style={{ textDecoration: 'underline' }} onClick={FileDownload}>
          {item[header.key]}
        </span>
      ) : (
        <span>-</span>
      )
    ) : header.key === 'period' ? (
      <span>{period || "-"}</span>
    ) : header.key === 'amountSanction' ? (
      "₹ " + new Intl.NumberFormat('en-IN').format(item[header.key])
    ) : header.key === 'completionPercentage' ? (
      item[header.key] + "%"
    ) : header.key === "academicYear" ? (
      item[header.key] ? item[header.key].replace("-", " - ") : "-"
    ) : (
      item[header.key] || "-"
    )}
  </td>
))}

                </tr>
              

                {/* extra datas for employee view table only  */}

                {index === 9 && employeeHeader === "Profile Info" && (
                  <tr>
                    <td className='extraDatas'>Personal Details</td>
                  </tr>
                )}

                {index === 17 && employeeHeader === "Profile Info" && (
                  <tr className='bgnone'>
                    <td className='extraDatas'>Contact Info</td>
                  </tr>
                )}

                {/* completed here */}

              </React.Fragment>
            ))}
          </tbody>

        </table>
      </div>
      {
        employeeHeader !== "Profile Info" &&
        <>
          {/* <span className='d-flex mt-3 cursorPointer ms-1'>
            <img src={crossicon} className='me-1'></img>
            <span style={{ color: "#CC5151", paddingLeft: "4px", font: '13px Satoshi Medium' }} onClick={() => handleDeleteClick()} >Delete this {deleteCont} </span>
          </span> */}
        </>
      }
      {/* <span className='d-flex mt-3 cursorPointer ms-1 timeStamp'>
=======
      {employeeHeader === "Profile Info" ? null : (
  <span className='d-flex mt-3 cursorPointer ms-1'>
    <img src={crossicon} className='me-2' />
    <span style={{color:"#CC5151" , paddingLeft:"4px" ,font:'13px Satoshi Medium'}} onClick={() => handleDeleteClick()} >Delete this {deleteCont} </span>
  </span>
)}
*/}
      {/* edit profile history */}

      <Modal show={editHisModal} onHide={() => SeteditHisModal(false)} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Profile Edit History</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>
            <table className="col-12 col-md-12 partiTable mt-3">
  <tbody>
    {editHistoryData?.map((rowData:any, dataIndex:any) => (
      <tr key={dataIndex} className='col-md-12 col-12'>
        {EditProfileHeaders.map((header:any, index:any) => (
          <td className='col-md-1 col-6' key={index}>
            {rowData[header.key]}
          </td>
        ))}
      </tr>
    ))}
  </tbody>
</table>
        </Modal.Body> */}
        <Modal.Body>
          <table className="col-12 col-md-12 partiTable mt-3">
            <tbody>
              {editHistoryData?.map((rowData: any, dataIndex: any) => (
                <tr key={dataIndex} className='col-md-12 col-12'>
                  <td className='col-md-1 col-6'>{rowData.filedName}</td>
                  <td className='col-md-1 col-6'>{rowData.oldValue}</td>
                  <td className='col-md-1 col-6'>{rowData.newValue}</td>
                  <td className='col-md-1 col-6'>{rowData.updatedOn}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>

        <Modal.Footer>

        </Modal.Footer>
      </Modal>

      {isModalOpen && (
        <div
          className="modal fade show"
          tabIndex={-1}
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content mt-2 mb-2">
              <div className='text-end'>
              <button
                  type="button"
                  className="btn-close" 
                  aria-label="Close"
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                ></button>
              </div>
              <div className="modal-header">
                <h5 className="modal-title">Delete Confirmation</h5>
              </div>
              <div className="modal-body">
                <p className="fontModel">
                  Are you sure you want to delete.You can't undo this action ?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn bg-white text-black cancelBtn"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btnBack"
                  onClick={() => {
                    deleteApiView();
                    setIsModalOpen(false);
                  }}
                >
                  Yes, delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Modal Overlay */}
      {isModalOpen && <div className="modal-backdrop fade show"></div>}
    </div>

<div className='paddingView'>
<span className='activities'>Activities</span>
<div className='activitiesTab'>
      {activityHistory.map((item: any, index: any) => (

        <div className='ActivityTab' key={index}>
          <div className='d-flex align-items-center gap-3'>
            {/* {item.academicYear} */}
            <div className='activityIcons'>
              {item.field == "Created" && <img src={created}/>}
              {item.field == "Updated" && <img src={created}/>}
              {item.field == "Submitted for validation" && <img src={submitted}/>}
              {item.field == "Rejected" && <img src={rejected}/>}
              {item.field == "Verified" && <img src={verified}/>}
              {item.field == "Created & Submitted for verification" && <img src={submitted}/>}
              {item.field == "Updated & Submitted for verification" && <img src={submitted}/>}
            </div>
            <div className='row'>
              <span className='createdOn'>{item.field}</span>
              {item.comments !== "" &&
              <div>
              <img src={commentsIcon} style={{paddingRight:"2px"}}></img>
              <span className='comment'>{item.comments}</span>
              </div>
              }
              <span className='createdDate'>{formatDate(item.value)}</span>
            </div>
          </div>
        </div>
      ))}
  </div>
</div>

{isModalOpen && (
        <div
          className="modal fade show"
          tabIndex={-1}
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content mt-2 mb-2">
            <div className='text-end'>
              <button
                  type="button"
                  className="btn-close" 
                  aria-label="Close"
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                ></button>
              </div>
              <div className="modal-header">
                <h5 className="modal-title">Delete Confirmation</h5>
              </div>
              <div className="modal-body">
                <p className="fontModel">
                  Are you sure you want to delete.You can't undo this action ?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn bg-white text-black cancelBtn"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btnBack"
                  onClick={() => {
                    deleteApiView();
                    setIsModalOpen(false);
                  }}
                >
                  Yes, delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

{reject && (
  <div
  className="modal fade show modal-md "
  tabIndex={-1}
  style={{ display: "block" }}
>
  <div className="modal-dialog modal-dialog-centered ">
    <div className="modal-content">
      <div className='text-end'>
    <button
          // style={{marginLeft:'94%'}}
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => {
            {setReject(false);setMandatory(false)}
          }}
        ></button>
        </div>
      <div className="mt-2 ">
        <h5 className="modal-title text-center col-12">Reject Entry</h5>
      </div>
      <div className='mt-3 mx-3'>
        <p className="fontModel px-md-5 mx-md-5 mb-1">
        This entry will be sent back to the faculty for revision. Faculty can correct the entry and submit again.
        </p>
        </div> 
      <div className="mt-2 d-flex flex-column align-items-center rejectModal">
         
        <div><textarea className='align-items-center textBox textboxplaceholder p-2' style={{resize:"none"}} placeholder='Enter the reason for rejection' value={textArea} onChange={(e)=>{setTextArea(e.target.value)}}/></div>
        {mandatory && textArea=='' && <div className='text-danger' style={{fontSize:'12px',fontFamily:'Satoshi Medium'}}>Please enter the reason for rejecion</div>}
      </div>
      <div className="mt-2 mb-5 d-flex  align-items-center justify-content-center">
        <button
          type="button" 
          className="btn bg-white text-black cancelBtn mx-1"
          onClick={() => {setReject(false);setMandatory(false)}}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btnBack mx-1"
          // onClick={()=>{
          //   setReject(false);
          // }}
          onClick={rejectCall}
        >
          Send
        </button>
      </div>
    </div>  
  </div>
</div>
)}
{reject && <div className="modal-backdrop fade show"></div> }

{approve && (
  <div
  className="modal fade show modal-sm "
  tabIndex={-1}
  style={{ display: "block" }}
>
  <div className="modal-dialog modal-dialog-centered ">
    <div className="modal-content">
      <div className='text-end'>
    <button
          // style={{marginLeft:'88%'}}
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => {
            setApprove(false);
          }}
        ></button>
        </div>
      <div className="mt-2 ">
        <h5 className="modal-title text-center col-12 px-12">Mark as Verified</h5>
      </div>
      <div className="mt-2 ">
        <p className="fontModel">
        Are you sure you want to mark this entry as verified. This action can not be undone.
        </p> 
      </div>
      <div className="mt-2 mb-5 d-flex justify-content-center">
        <button
          type="button"
          className="btn bg-white text-black cancelBtn mx-1"
          onClick={() => setApprove(false)}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btnBack mx-1"
          onClick={approveCall}
        >
          Yes, Proceed
        </button>
      </div>
    </div>  
  </div>
</div>
)}
{approve && <div className="modal-backdrop fade show"></div> }


{submitToggle && (
        <div
          className="modal fade show modal-sm"
          tabIndex={-1}
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <button
                  style={{marginLeft:'88%'}}
                  type="button"
                  className="btn-close mt-2"
                  aria-label="Close"
                  onClick={() => {
                    setSubmitToggle(false);
                  }}
                ></button>
              <div className="mt-4">
                <h5 className="modal-title text-center col-12">Submit for validation</h5>
              </div>
              <div className="mt-2">
                <p className="fontModel">
                This entry will be sent to admin <br/>
                for validation.
                </p>
              </div>
              <div className="mt-2 mb-5 text-center">
                <button
                  type="button"
                  className="btn bg-white text-black cancelBtn mx-1"
                  onClick={() => setSubmitToggle(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btnBack mx-1"
                  // onClick={() => {()=>TableSubmit(selectedItem);
                  //   setSubmitToggle(false);
                  // }}
                  onClick={()=>{
                    TableSubmit(selectedItem)
                    setSubmitToggle(false);
                    // window.location.reload();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>  
          </div>
        </div>
      )}
         {submitToggle && <div className="modal-backdrop fade show"></div>}
    </>
      
  );
}

export default CourseTable;
