import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{ onLoad: "login-required", }}
  >
    <BrowserRouter basename="">
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </ReactKeycloakProvider>
);

{
  /* <GoogleOAuthProvider clientId="45064868996-3mc380pb67hnd47gram4lk4d1ehffnst.apps.googleusercontent.com"></GoogleOAuthProvider> */
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
